import { MenuEntry } from '@nerveglobal/greenhouse-uikit'

export const config: MenuEntry[] = [
  {
    label: "Exchange",
    href: "https://trade.greenhousedex.com/",
  },
  {
    label: "Farms",
    href: "https://greenhousedex.com/farms",
  },
  {
    label: "Pools",
    href: "https://greenhousedex.com/pools",
  },
  {
    label: "Stake",
    href: "https://greenhousedex.com/stake",
  },
  {
    label: "Bridges",
    href: "https://greenhousedex.com/bridges",
  },
  {
    label: "Analytics",
    href: "https://analytics.greenhousedex.com/",
  },
  {
    label: "Migrate",
    href: "https://greenhousedex.com/migration",
  },
];
export const sideconfig: MenuEntry[] = [
];

export default config